/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getBalanceRoutesData } from "@hyperlocal/banking-utility";
import { Route, Routes as Switch } from "react-router-dom";
import { useCheckAppVersion } from "../api/use-check-app-version";
import Balance from "../components/Balance";
import BalanceDashboard from "../components/BalanceDashboard";

export const Routes = () => {
  const routes = getBalanceRoutesData();
  useCheckAppVersion();
  return (
    <Switch>
      <Route path={routes.dashboard} element={<BalanceDashboard />} />
      <Route path="*" element={<Balance />} />
    </Switch>
  );
};
