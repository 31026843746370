import { useEffect, useState } from "react";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { appVersionQueryFactory, BALANCE_CDN } from "../utils";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);
  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const queryOptions = appVersionQueryFactory.check({
    url: BALANCE_CDN,
    route,
    storageKey: "BALANCE_VERSION",
  }) as unknown as UseQueryOptions<string, Error, string, string[]>;

  return useQuery({ ...queryOptions, retry: false });
}
